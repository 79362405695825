import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Grid,
  Pagination,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DoneIcon from '@mui/icons-material/Done';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import {
  Text,
  Loading,
  ImageHeader,
  EmptyList,
  Alert,
  RadioButton,
  Tag,
  ErrorState,
} from '../../components';
import { DateInput } from '../../components/Form';
import { styles } from './styles';
import '../../styles/table.css';
import { FlexDirectionRowCenter, TableRowStyled } from '../../styles/components';
import GlobalStyles from '../../styles/GlobalStyles';
import pageIcon from '../../assets/images/iconsQuickAction/request.png';
import RequestRHService from '../../services/napoleon/RequestRHService';
import StatusRequestRHService from '../../services/napoleon/StatusRequestRH';
import ImgEmptyList from '../../assets/images/icon_emptymessage.png';
import { isValidDate } from '../../utils';
import ModalDetail from './components/ModalDetail';

export default function Requests() {
  const requestRHService = new RequestRHService();
  const statusRequestRHService = new StatusRequestRHService();
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  /* eslint-disable */
  const [radioOrderOptions, setRadioOrderOptions] = useState([
    { id: 1, text: 'Mais recentes', value: 'DESC', selected: true },
    { id: 2, text: 'Mais antigas', value: 'ASC', selected: false },
  ]);
  const [radioStatusOptions, setRadioStatusOptions] = useState(null);
  const radioStatusOptionsDefaultValue = { id: undefined, name: 'Todas', selected: true };
  /* eslint-enable */
  const [orderRequests, setOrderRequests] = useState(radioOrderOptions[0].value);
  const [statusRequest, setStatusRequest] = useState(null);
  /* Controls the exhibition of the Loading component */
  const [loading, setLoading] = useState(false);
  /* Used to guarantee that the functions getRequests() and getStatusRequests()
    will not be called more than once */
  let lookingForRequests = false;
  let lookingForStatusRequests = false;
  const [hasError, setHasError] = useState(false);
  const [page, setPage] = useState(1);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const [totalPages, setTotalPages] = useState(0);
  const [requests, setRequests] = useState(null);
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [alertOptions, setAlertOptions] = useState({
    severity: '',
    snackbarText: '',
    duration: 3000,
    icon: '',
  });
  const [selectedRequest, setSelectedRequest] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuOptions = Boolean(anchorEl);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const handleClickOptions = (event) => setAnchorEl(event.currentTarget);
  const handleCloseOptions = () => setAnchorEl(null);
  const requestsDispatch = useDispatch();

  const handleClickSnackbar = ({
    severity,
    snackbarText,
    duration,
    icon,
  }) => {
    setAlertOptions({
      severity,
      snackbarText,
      duration: duration || 3000,
      icon,
    });
    setOpenSnackBar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  // eslint-disable-next-line max-len
  const isRequestSolved = () => selectedRequest?.status?.id === statusRequestRHService.status.solved.id;

  const getRequests = () => {
    if (lookingForRequests) return;

    lookingForRequests = true;
    setLoading(true);

    const start = startDate ? moment(startDate).format('YYYY-MM-DD 00:00:01') : undefined;
    const end = finalDate ? moment(finalDate).format('YYYY-MM-DD 23:59:59') : undefined;

    requestRHService.getRequestsRH(page, 10, statusRequest, orderRequests, start, end)
      .then((response) => {
        setHasError(false);
        const { messages, pages } = response.data;
        setRequests(messages);
        setTotalPages(pages);
      })
      .catch((error) => {
        setRequests(null);
        setHasError(true);
        throw error;
      })
      .finally(() => {
        lookingForRequests = false;
        if (!lookingForStatusRequests) setLoading(false);
      });
  };

  const getStatusRequests = () => {
    if (lookingForStatusRequests) return;

    lookingForStatusRequests = true;
    setLoading(true);

    statusRequestRHService.getStatus()
      .then((response) => {
        setHasError(false);
        const options = [];
        options.push(radioStatusOptionsDefaultValue);
        for (let i = 0; i < response.data.length; i++) {
          options.push(response.data[i]);
        }
        setRadioStatusOptions(options);
      })
      .catch((error) => {
        setRadioStatusOptions(null);
        setHasError(true);
        throw error;
      })
      .finally(() => {
        lookingForStatusRequests = false;
        if (!lookingForRequests) setLoading(false);
      });
  };

  const shouldNavigate = (event, request) => {
    const html = event.target.outerHTML.substring(0, 10);
    // When the user clicked in the More options Icon should navigate
    if (html.includes('<td') || html.includes('<span') || html.includes('<img')) {
      setSelectedRequest(request);
      setOpenModalDetail(true);
    }
  };

  const collaboratorName = (collaborator) => {
    const { name } = collaborator;
    return name || 'Nome não cadastrado';
  };

  const resolveRequest = (requestId) => {
    requestRHService.resolveRequest(requestId, requestsDispatch)
      .then((result) => {
        const requestsTemp = [...requests];
        const idx = requestsTemp.findIndex((request) => request.id === selectedRequest.id);
        requestsTemp[idx] = result.data;
        setRequests(requestsTemp);
        handleClickSnackbar({ severity: 'success', snackbarText: 'Solicitação resolvida com sucesso!' });
      })
      .catch((error) => {
        handleClickSnackbar({ severity: 'error', snackbarText: 'Erro ao resolver solicitação. Tente novamente mais tarde' });
        throw error;
      });
  };

  const answerRequest = (collaborator, updateRequest = false) => {
    const requestToUpdate = updateRequest && selectedRequest.id;
    history.push('/admin/create-message', { path: 'directMessage', collaborator, requestToUpdate });
  };

  useEffect(() => {
    if (!startDate && !finalDate) {
      getRequests();
      return;
    }

    if (!startDate || !finalDate) return;

    try {
      const startDateISOString = startDate.toISOString();
      const finalDateISOString = finalDate.toISOString();
      const yearStartDate = startDateISOString.substring(0, 10).split('-')[0];
      const yearFinalDate = finalDateISOString.substring(0, 10).split('-')[0];

      if (yearStartDate < 1000 || yearFinalDate < 1000) return;

      if (startDate.getTime() > finalDate.getTime()) {
        handleClickSnackbar({ severity: 'error', snackbarText: 'A data inicial não pode ser maior do que a data final!' });
        return;
      }

      if (isValidDate(startDate.toISOString()) && isValidDate(finalDate.toISOString())) {
        getRequests();
      }
    } catch (error) {
      /* eslint-disable */
      return error;
      /* eslint-enable */
    }
  }, [startDate, finalDate]);

  useEffect(() => getRequests(), [page, statusRequest, orderRequests]);

  useEffect(() => getStatusRequests(), []);

  if (hasError) return <ErrorState msg='Recarregue a página para ver as solicitações.' />;

  if (loading) return <Loading />;

  return (
    <Container>
      <Grid item xs={12} style={GlobalStyles.justifyContentRowStart} marginBottom={2}>
        <FlexDirectionRowCenter>
          <ImageHeader src={pageIcon} />
          <Text variant='h2'>Solicitações</Text>
        </FlexDirectionRowCenter>
      </Grid>

      <Grid container columnSpacing={2} rowSpacing={1} style={GlobalStyles.justifyContentRowCenter}>
        {/* Initial date */}
        <Grid item xs={6} sm={2} md={2} style={GlobalStyles.justifyContentRowStart}>
          <DateInput
            label='Data inicial'
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            disableFuture
            inputFormat='dd/MM/yyyy'
          />
        </Grid>

        {/* Final date */}
        <Grid item xs={6} sm={2} md={2} style={GlobalStyles.justifyContentRowStart}>
          <DateInput
            label="Data final"
            value={finalDate}
            onChange={(newValue) => setFinalDate(newValue)}
            inputFormat='dd/MM/yyyy'
          />
        </Grid>

        <Grid item xs={12} sm={3} md={3} sx={styles.spacingLeft}>
          <Text variant='hintText' style={GlobalStyles.xsPaddinBottom}>Período</Text>
          <Grid container style={GlobalStyles.justifyContentRowStart}>
            {
              radioOrderOptions.map((item, key) => (
                <RadioButton
                  text={item.text}
                  selected={item.selected}
                  key={key}
                  onClick={() => {
                    const options = [...radioOrderOptions];
                    if (options[key].selected) return;
                    options[key].selected = true;
                    setOrderRequests(options[key].value);
                    setPage(1);
                    /* eslint-disable */
                    options.map((option, idx) => {
                      if (idx !== key)
                        option.selected = false;
                    })
                    /* eslint-enable */
                    setRadioOrderOptions(options);
                  }}
                />
              ))
            }
          </Grid>
        </Grid>

        { radioStatusOptions && radioStatusOptions.length > 0
          ? (
            <Grid item xs={12} sm={5} md={4}>
              <Text variant='hintText' style={GlobalStyles.xsPaddinBottom}>Status</Text>
              <Grid container style={GlobalStyles.justifyContentRowStart}>
                {
                  radioStatusOptions.map((item, key) => (
                    <RadioButton
                      text={item.name}
                      selected={item.selected}
                      key={key}
                      onClick={() => {
                        const options = [...radioStatusOptions];
                        if (options[key].selected) return;
                        options[key].selected = true;
                        setStatusRequest(options[key].id);
                        setPage(1);
                        /* eslint-disable */
                        options.map((option, idx) => {
                          if (idx !== key)
                            option.selected = false;
                        })
                        /* eslint-enable */
                        setRadioStatusOptions(options);
                      }}
                    />
                  ))
                }
              </Grid>
            </Grid>
          ) : null
        }

        {
          requests && requests.length > 0
            ? (
              <>
                {/* Pagination */}
                <Grid container item>
                  <Grid item xs={12} style={GlobalStyles.justifyContentRowFlexEnd}>
                    <Pagination
                      boundaryCount={1}
                      siblingCount={1}
                      page={page}
                      onChange={handleChangePage}
                      count={totalPages}
                      color='primary'
                      size='small'
                    />
                  </Grid>
                </Grid>

                  {/* Table */}
                  <Grid container style={GlobalStyles.smallPaddingTop}>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table aria-label="customized table">
                          <TableBody>
                          {/* eslint-disable */}
                            {
                              requests.map((request, key) => (
                                <TableRowStyled key={key} onClick={(event) => shouldNavigate(event, request)}>
                                  <TableCell>{moment(request.createdAt).format('DD/MM/YYYY')} - {moment(request.createdAt).format('HH:mm')}</TableCell>
                                  <TableCell>{collaboratorName(request.collaborator)}</TableCell>
                                  <TableCell>{request.subject}</TableCell>
                                  <TableCell><Tag color={request.status?.color}>{request.status?.name}</Tag></TableCell>
                                  <TableCell align='right'>
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      aria-controls={openMenuOptions ? 'long-menu' : undefined}
                                      aria-expanded={openMenuOptions ? 'true' : undefined}
                                      aria-haspopup="true"
                                      onClick={(event) => { handleClickOptions(event); setSelectedRequest(request) }}
                                    >
                                      <MoreHorizIcon color='primary' fontSize='small' />
                                    </IconButton>
                                  </TableCell>
                                </TableRowStyled>
                              ))
                            }
                          {/* eslint-enable */}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Menu
                        id="long-menu"
                        MenuListProps={{ 'aria-labelledby': 'long-button' }}
                        anchorEl={anchorEl}
                        open={openMenuOptions}
                        onClose={handleCloseOptions}
                        PaperProps={{ style: styles.optionsPopover }}
                      >
                        <MenuItem
                          onClick={() => {
                            setOpenModalDetail(true);
                            handleCloseOptions();
                          }}
                        >
                          <InfoOutlinedIcon color='secondary_light' fontSize='small'/>&nbsp;
                          <Text variant='hintText'>Informações</Text>
                        </MenuItem>

                        {!isRequestSolved() && (
                          <MenuItem
                            onClick={() => {
                              answerRequest(selectedRequest.collaborator);
                              handleCloseOptions();
                            }}
                          >
                            <ReplyOutlinedIcon color='secondary_light' fontSize='small'/>&nbsp;
                            <Text variant='hintText'>Responder</Text>
                          </MenuItem>
                        )}

                        {!isRequestSolved() && (
                          <MenuItem
                            onClick={() => {
                              resolveRequest(selectedRequest.id);
                              handleCloseOptions();
                            }}
                            disabled={isRequestSolved()}
                          >
                            <DoneIcon color='secondary_light' fontSize='small'/>&nbsp;
                            <Text variant='hintText'>Marcar como resolvido</Text>
                          </MenuItem>
                        )}
                      </Menu>
                    </Grid>
                  </Grid>
              </>
            ) : <EmptyList srcImage={ImgEmptyList} text='Não há nenhuma solicitação.' />
        }

        <ModalDetail
          open={openModalDetail}
          setOpenModal={setOpenModalDetail}
          request={selectedRequest}
          resolveRequest={resolveRequest}
          answerRequest={answerRequest}
          isRequestSolved={isRequestSolved}
        />

      </Grid>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={alertOptions.duration}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertOptions.severity}
          icon={alertOptions.icon}
          color={alertOptions.severity ? null : 'secondary_light'}
        >
          {alertOptions.snackbarText}
        </Alert>
      </Snackbar>
    </Container>
  );
}
