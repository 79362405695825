import styled from 'styled-components';
import createStyles from '@mui/styles/createStyles';
import COLORS from '../../styles/Colors';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;
  align-content: center;
`;

export const ContainerMsg = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${COLORS.LIGHT};
  padding-left: 4%;
  justify-content: center;
`;

export const Logo = styled.img`
  height: 26px;
  width: 110px;
  top: 5%;
  left: 4%;
  position: absolute;
`;

export const Title = styled.h1`
  color: ${COLORS.PRIMARY};
  font-size: 42px;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10%;
`;

export const ContainerLoginForm = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  justify-content: center;
  align-items: center;
`;

export const ErrorLogin = styled.div`
  background-color: ${COLORS.INACTIVE_LIGHT};
  height: 30px;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const styles = createStyles({
  logo: {
    position: 'absolute',
    top: 30,
  },
  subTitle: {
    fontWeight: 'normal',
  },
});
