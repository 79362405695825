import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormService from '../../../../services/napoleon/FormService';
import { PreviewContainer } from './styles';
import { ButtonComponent, Text, Loading } from '../../../../components';
import { ContainerFullPage } from '../../../../styles/components';
import GlobalStyles from '../../../../styles/GlobalStyles';

function PreviewList() {
  const listPreview = useSelector((state) => state.formReducer.listPreview);
  const selectedFields = useSelector((state) => state.formReducer.selectedFields);
  const formId = useSelector((state) => state.formReducer.id);
  const collaboratorsFormData = useSelector((state) => state.formReducer.collaboratorsFormData);
  const fileName = useSelector((state) => state.formReducer.file_collaborators.fileName);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const formService = new FormService();
  const importErrors = useDispatch();
  let hasValidateFileError = false;
  let hasAnyFileError = false;

  const mergeFieldValidation = (warning, error) => {
    const differentValidationsHasMerged = [];
    let validations = {};

    Object.keys(warning).map((warningField) => {
      Object.keys(error).map((errorField) => {
        if (warningField === errorField) {
          differentValidationsHasMerged.push(errorField);
          validations = Object.assign(validations, {
            [warningField]: { ...warning[errorField], ...error[errorField] },
          });
        } else if (!differentValidationsHasMerged.includes(errorField)) {
          validations = Object.assign(validations, { [errorField]: { ...error[errorField] } });
        }

        return error[errorField];
      });

      if (!differentValidationsHasMerged.includes(warningField)) {
        validations = Object.assign(validations, { [warningField]: { ...warning[warningField] } });
      }

      return warning[warningField];
    });

    if (Object.keys(warning).length <= 0) {
      Object.keys(error).map((errorField) => {
        if (!differentValidationsHasMerged.includes(errorField)) {
          validations = Object.assign(validations, { [errorField]: { ...error[errorField] } });
        }

        return error[errorField];
      });
    }

    return validations;
  };

  const validateColumnsCollaboratorsFile = () => {
    setLoading(true);

    const formData = new FormData();

    formData.set('collaboratorsFile', collaboratorsFormData.collaboratorsFile);
    formData.set('selectedFields', `{"selectedFields":${JSON.stringify(selectedFields)}}`);

    formService
      .validateColumnsCollaboratorsFile(formId, formData)
      .then(() => {
        hasValidateFileError = false;
        hasAnyFileError = false;
      })
      .catch((responseError) => {
        const { response } = responseError;

        if (response) {
          const {
            data: { warning, error },
          } = response;
          importErrors({
            type: 'FIND_IMPORT_ERRORS',
            importValidateErrors: mergeFieldValidation(warning, error),
          });
          hasValidateFileError = true;
        } else {
          hasAnyFileError = true;
        }
        throw responseError;
      })
      .finally(() => {
        setLoading(false);
        if (hasValidateFileError) {
          history.push('/admin/report-error-collaborators-import');
        } else if (hasAnyFileError) {
          history.push('/admin/error-collaborators-import-validate', { fileName });
        } else {
          history.push('/admin/success-collaborators-import-validate', {
            title: 'Seu arquivo foi importado com sucesso.',
            subTitle: 'Está tudo pronto para começar a comunicar com seu time de maneira eficiente!',
            buttonText: 'Finalizar',
          });
        }
      });
  };

  const FieldColumnName = ({ fieldColumnName }) => {
    const fieldColumnsName = listPreview.filter((item) => item.field === fieldColumnName);
    if (fieldColumnsName.length > 0) {
      return (
        fieldColumnsName.map((e, i) => (
          <Text variant='hintText' key={i} lineHeight={2}>{e.value}</Text>
        ))
      );
    }
    return <Text variant='hintText' lineHeight={2}>Não foi mapeado</Text>;
  };

  if (loading) return <Loading title='Processando…' />;

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant='h1'>Confirme as colunas mapeadas</Text>
      <Text variant='hintText' style={GlobalStyles.smallMarginTop}>
        Verifique se as informações do colaborador correspondem às colunas selecionadas.
      </Text>

      {selectedFields.map((prop, index) => (
        <PreviewContainer key={index}>
          <Text variant='h4' lineHeight={2}>{prop.formFieldName}</Text>
          <FieldColumnName fieldColumnName={prop.fieldColumnName} />
        </PreviewContainer>
      ))}

      <Grid container style={GlobalStyles.justifyContentRowSpaceEvenly}>
        <ButtonComponent
          variant="outlined"
          size='medium'
          style={GlobalStyles.mediumMarginTop}
          onClick={() => history.goBack()}
        >
          Retornar
        </ButtonComponent>

        <ButtonComponent
          size='medium'
          style={GlobalStyles.mediumMarginTop}
          onClick={validateColumnsCollaboratorsFile}
        >
          Está correto
        </ButtonComponent>
      </Grid>
    </ContainerFullPage>
  );
}

export default PreviewList;
