import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';
import COLORS from '../../../styles/Colors';

const StyledInput = styled(TextField)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export default StyledInput;

export const useStyles = makeStyles({
  styles: (props) => ({
    '& .MuiInput-underline:before': {
      borderBottomColor: props.error ? COLORS.RED : COLORS.SECONDARY,
      borderWidth: 1,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: props.error ? COLORS.RED : COLORS.PRIMARY,
      borderWidth: 1,
    },
    '&:hover .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderWidth: 1,
      borderBottomColor: props.error ? COLORS.RED : COLORS.PRIMARY,
    },
  }),
});
