import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import FormService from '../../../../../../services/napoleon/FormService';
import fieldHintSample from '../../../../../../assets/images/planilha_exemplo.png';
import { Text, ButtonComponent, Loading } from '../../../../../../components';
import { Img, UploadInput, styles } from './styles';
import GlobalStyles from '../../../../../../styles/GlobalStyles';
import { ContainerFullPage } from '../../../../../../styles/components';

export default function Instructions() {
  let hasFileError = false;
  const formId = useSelector((state) => state.formReducer.id);
  const collaboratorsFileErrorDispatch = useDispatch();
  const formDataDispatch = useDispatch();
  const history = useHistory();
  const fileDispatch = useDispatch();
  const clearFieldSelected = useDispatch();
  const formService = new FormService();
  // eslint-disable-next-line max-len
  const formFields = useSelector((state) => state.formReducer.formElement.filter((message) => message.active));
  const fileInput = React.useRef();
  const [selectedFileName, setSelectedFileName] = useState('');
  const [loading, setLoading] = useState(false);

  const analyseColumnsCollaboratorsFile = (formData) => {
    setLoading(true);
    formService
      .analyseColumnsCollaboratorsFile(formId, formData)
      .then(({ data: { fileName, headers, collaborators } }) => {
        formDataDispatch({
          type: 'FIND_FORM_DATA',
          collaboratorsFormData: {
            collaboratorsFile: formData.get('collaboratorsFile'),
          },
        });
        collaboratorsFileErrorDispatch({
          type: 'HANDLER_COLLABORATORS_FILE_ERROR',
          handlerError: {
            hasError: false,
            message: '',
          },
        });
        hasFileError = false;
        fileDispatch({
          type: 'HANDLER_FILE',
          file_collaborators: { fileName, headers, collaborators },
        });
        clearFieldSelected({ type: 'HANDLER_SELECTED_FIELDS', selectedFields: [] });
      })
      .catch((error) => {
        collaboratorsFileErrorDispatch({
          type: 'HANDLER_COLLABORATORS_FILE_ERROR',
          handlerError: {
            hasError: true,
            message: `Houve um erro ao carregar o arquivo "${
              formData.get('collaboratorsFile').name
            }"`,
          },
        });
        hasFileError = true;
        throw error;
      })
      .finally(() => {
        setLoading(false);

        if (hasFileError) {
          history.push('/admin/error-collaborators-import-validate', {
            fileName: formData.get('collaboratorsFile').name,
          });
        } else {
          history.push('/admin/map-collaborators');
        }
      });
  };

  const handleFile = (event) => {
    const [file] = event.target.files;
    setSelectedFileName(file.name);

    const formData = new FormData();
    formData.append('collaboratorsFile', file);

    analyseColumnsCollaboratorsFile(formData);
  };

  if (loading) {
    return <Loading showLoadingLabel={true} title={selectedFileName} loadingLabel='Carregando arquivo…' />;
  }

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant='h1'>Importar arquivo</Text>
      <Text variant='hintText'>
        Utilize as instruções abaixo para preparar o arquivo que será importado.
      </Text>

      <Grid item xs={12} style={styles.informationContainer}>
        <Grid item xs={12}>
          <Text variant='h4'>FORMATOS ACEITOS</Text>
          <Text variant='hintText'>.xlsx e .csv</Text>
        </Grid>

        <Grid item xs={12} style={styles.informationContainer}>
          <Text variant='h4'>COLUNAS OBRIGATÓRIOS</Text>
          {formFields?.map((field, index) => (
            <Text variant='hintText' key={index} style={styles.requiredField}>{ field.name }</Text>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} style={styles.informationContainer}>
        <Text variant='h4'>EXEMPLO DE PLANILHA:</Text>
        <Img src={fieldHintSample}></Img>
      </Grid>

      <Grid item xs={12} style={styles.informationContainer} textAlign='center'>
        <ButtonComponent size='medium' onClick={() => fileInput.current.click()}>
          Selecionar arquivo
        </ButtonComponent>
        <UploadInput
          ref={fileInput}
          type="file"
          id="collaborators-file"
          accept=".csv,.xlsx"
          onChange={(event) => handleFile(event)}
        />
      </Grid>
    </ContainerFullPage>
  );
}
