import PropTypes from 'prop-types';
import logoDark from '../../assets/images/logo.png';
import logoLight from '../../assets/images/logo_white.png';
import Img from './styles';

/**
* @param {{
*  mode: 'light' | 'dark'
* }} props
*/

const Logo = ({ mode, style }) => (
  <Img src={mode === 'dark' ? logoDark : logoLight} style={style} />
);

Logo.defaultProps = {
  mode: 'dark',
};

Logo.propTypes = {
  mode: PropTypes.oneOf(['light', 'dark']),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Logo;
