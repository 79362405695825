import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import { Img } from './styles';
import errorImg from '../../../../../../assets/images/icons/icon_error.png';
import { ContainerFullPageWithoutBackButton } from '../../../../../../styles/components';
import GlobalStyles from '../../../../../../styles/GlobalStyles';
import { Text, ButtonComponent } from '../../../../../../components';

function ErrorImportFileMessage(props) {
  const history = useHistory();
  const collaboratorsFileErrorDispatch = useDispatch();

  const resetState = () => {
    collaboratorsFileErrorDispatch({
      type: 'HANDLER_COLLABORATORS_FILE_ERROR',
      handlerError: {
        hasError: false,
        message: '',
      },
    });
    history.push('/admin/collaborators-import');
  };

  function message() {
    if (props?.location?.state?.fileName) {
      return `Houve um erro ao carregar o arquivo "${props.location.state.fileName}"`;
    }

    return 'Não foi possível importar a lista de colaboradores. Tente novamente.';
  }

  return (
    <ContainerFullPageWithoutBackButton
      container
      spacing={0}
      sx={GlobalStyles.containerFullPageWithoutBackButton}
    >
      <Grid item xs={12}>
        <Img src={errorImg}></Img>
      </Grid>
      <Grid item xs={12}>
        <Text variant='h2' color='secondary' lineHeight={2}>
          {message()}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text variant='hintText'>
          Este arquivo parece estar em branco ou possui um formato não compatível.
        </Text>
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 30 }}>
        <ButtonComponent size='medium' onClick={resetState}>
          Tentar novamente
        </ButtonComponent>
      </Grid>
    </ContainerFullPageWithoutBackButton>
  );
}

export default ErrorImportFileMessage;
