import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ContainerMain, ContainerButtons, styles } from './styles';
import { Text, CollaboratorAdhesionGraph, EmptyList } from '../../components';
import HomeButton from './components/HomeButton';
import MessageService from '../../services/napoleon/MessageService';
import RequestRHService from '../../services/napoleon/RequestRHService';
import ComplaintsService from '../../services/napoleon/ComplaintsService';
import generalMessagesIcon from '../../assets/images/iconsQuickAction/board.png';
import directMessagesIcon from '../../assets/images/iconsQuickAction/message.png';
import benefitsCardIcon from '../../assets/images/iconsQuickAction/card.png';
import employeesIcon from '../../assets/images/iconsQuickAction/employees.png';
import ImgPathNoMessages from '../../assets/images/icon_emptymessage.png';
import { percentage } from '../../utils';

export default function Home() {
  const complaintsDispatch = useDispatch();
  const requestsDispatch = useDispatch();
  const { newComplaints } = useSelector((state) => state.complaintsReducer);
  const { newRequests } = useSelector((state) => state.requestsReducer);
  const messageService = new MessageService();
  const requestRHService = new RequestRHService();
  const complaintsService = new ComplaintsService();
  const [messages, setMessages] = useState([]);
  const homeButtons = [
    {
      imagePath: generalMessagesIcon,
      text: 'Nova postagem no mural',
      route: '/admin/create-message',
      params: { path: 'home' },
    },
    {
      imagePath: directMessagesIcon,
      text: 'Enviar nova mensagem direta',
      route: '/admin/direct-message',
    },
    {
      imagePath: benefitsCardIcon,
      text: 'Recarregar benefícios nos cartões',
      isExternalRoute: true,
      route: 'https://empresa.volus.com',
    },
    {
      imagePath: employeesIcon,
      text: 'Adicionar ou excluir colaboradores',
      route: '/admin/collaborators-import',
    },
  ];
  const history = useHistory();

  const MessagesSection = () => {
    if (messages.length > 0) {
      return (
        messages.map((message, key) => (
          <Grid item container xs={12} style={styles.gridItem} key={key}>
            <Text variant='hintText'>{message.subject}</Text>
            <Text variant='hintText'>{percentage(message.readRate)}%</Text>
          </Grid>
        ))
      );
    }

    return <EmptyList srcImage={ImgPathNoMessages} text='Não há nenhuma mensagem no mural.' />;
  };

  const handleNavigation = (prop) => {
    if (prop.isExternalRoute) {
      window.open(prop.route, '_blank');
      return;
    }

    if (prop.route) history.push(prop.route, prop.params);
  };

  const getMessages = () => {
    messageService
      .getMessagesList(1, 4)
      .then((response) => {
        const { docs } = response.data;
        setMessages(docs);
      });
  };

  const getNewRequests = () => {
    requestRHService
      .getNewRequests()
      .then((response) => {
        requestsDispatch({ type: 'FIND_REQUESTS', newRequests: response.data.amount });
      })
      .catch(() => {
        requestsDispatch({ type: 'FIND_REQUESTS', newRequests: 0 });
      });
  };

  const getAmountNewComplaints = () => {
    complaintsService
      .getAmountNewComplaints()
      .then((response) => {
        complaintsDispatch({ type: 'FIND_COMPLAINTS', newComplaints: response.data.amount });
      })
      .catch(() => {
        complaintsDispatch({ type: 'FIND_COMPLAINTS', newComplaints: 0 });
      });
  };

  useEffect(() => {
    getMessages();
    getNewRequests();
    getAmountNewComplaints();
  }, []);

  return (
    <ContainerMain>
      <Text variant='h3'>Ações rápidas</Text>

      <ContainerButtons>
        <Grid container rowSpacing={1}>
          {
            homeButtons.map((prop, key) => (
              <Grid item xs={6} lg={3} container key={key}>
                <HomeButton
                  imagePath={prop.imagePath}
                  text={prop.text}
                  onClick={() => handleNavigation(prop)}
                  comingSoon={prop.comingSoon}
                />
              </Grid>
            ))
          }
        </Grid>
      </ContainerButtons>

      <Divider sx={styles.spacing}/>

      <Grid container columnSpacing={2} rowSpacing={1} style={styles.containerGridRow}>
        {/* Left side */}
        <Grid item lg={6} md={6} sm={12} xs={12} style={styles.containerGridRow}>
          <Grid container style={styles.containerGridRow}>
            <Text variant='h3'>Adesão ao app</Text>
            <CollaboratorAdhesionGraph calledByHome={true} />
          </Grid>
        </Grid>

        {/* Right side */}
        <Grid item lg={6} md={6} sm={12} xs={12} style={styles.containerGridReadRate}>
          <Grid container style={styles.containerGridRow}>
            <Text variant='h3'>
              Visualizações das últimas postagens
            </Text>
            <MessagesSection />
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={styles.spacing}/>

      <Grid container spacing={1} rowSpacing={2} style={styles.containerGridRow}>
        <Grid item lg={6} md={6} sm={6} xs={12} container style={styles.gridItemRequestsComplaints}>
          <Text variant='h3' style={styles.spacingBottom}>Solicitações recentes</Text>
          <HomeButton
            title={newRequests.toString()}
            text='Novas Solicitações'
            onClick={() => handleNavigation({ route: '/admin/requests' })}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12} container style={styles.gridItemRequestsComplaints}>
          <Text variant='h3' style={styles.spacingBottom}>Denúncias</Text>
          <HomeButton
            title={newComplaints.toString()}
            text='Novas Denúncias'
            onClick={() => handleNavigation({ route: '/admin/complaints' })}
          />
        </Grid>
      </Grid>
    </ContainerMain>
  );
}
