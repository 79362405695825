/* eslint-disable no-alert */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {
  DescriptionContainer,
  DescriptionIconContainer,
  DescriptionIcon,
  DescriptionDivider,
  styles,
} from './styles';
import warningImg from '../../../../../../assets/images/icons/warning.png';
import errorImg from '../../../../../../assets/images/icons/error.png';
import { ContainerFullPage } from '../../../../../../styles/components';
import GlobalStyles from '../../../../../../styles/GlobalStyles';
import { ButtonComponent, Text } from '../../../../../../components';

export default function ReportErrorFile() {
  const listPreview = useSelector((state) => state.formReducer.listPreview);
  const selectedFields = useSelector((state) => state.formReducer.selectedFields);
  const reportError = useSelector((state) => state.formReducer.importValidateErrors);
  const history = useHistory();

  const validationTemplate = (key, image, message) => (
    <div key={key}>
      <DescriptionContainer>
        <DescriptionIconContainer>
          <DescriptionIcon src={image} />
        </DescriptionIconContainer>
        <Text variant='hintText'>{message}</Text>
      </DescriptionContainer>
    </div>
  );

  const renderValidationType = (field, error, validationType, key) => {
    if (validationType === 'hasEmpty') {
      const image = field === 'Celular' ? warningImg : errorImg;
      const message = `Esta coluna possui ${error[validationType].length} campo(s) em branco.`;

      return validationTemplate(key, image, message);
    }

    if (validationType === 'hasDuplicate' && field === 'CPF') {
      const message = 'Há CPFs duplicados.';

      return validationTemplate(key, errorImg, message);
    }

    if (validationType === 'hasWrongFormat') {
      let message = '';

      if (field === 'Data de nascimento') {
        message = 'A coluna Data de nascimento deve estar no formato data';
      }

      if (field === 'Celular') {
        message = `A coluna ${field} deve ter 11 caracteres numéricos, com ou sem pontuação, no formato texto.`;
      }

      if (field === 'CPF') {
        message = 'A coluna CPF deve ter 11 caracteres numéricos, com ou sem pontuação, no formato texto e os CPFs devem ser válidos.';
      }

      return validationTemplate(key, errorImg, message);
    }

    if (validationType === 'hasWrongMaxLength') {
      const [validation] = error[validationType];
      const message = `A coluna ${field} deve ter no máximo ${validation.maxLength} caracteres, há ${error[validationType].length} campo(s) com esse erro.`;

      return validationTemplate(key, errorImg, message);
    }

    if (validationType === 'hasWrongMinLength') {
      const [validation] = error[validationType];
      const message = `A coluna ${field} deve ter no máximo ${validation.minLength} caracteres, há ${error[validationType].length} campo(s) com esse erro.`;

      return validationTemplate(key, errorImg, message);
    }

    return null;
  };

  const renderFieldValues = (field) => {
    const fieldValues = [];
    selectedFields.map((prop) => listPreview
      .filter((item) => item.field === prop.fieldColumnName && prop.formFieldName === field)
      .map((e) => fieldValues.push(e.value)));
    return fieldValues;
  };

  const renderCpfReportError = (array, title) => (
    <Grid item xs={12}>
      <Text variant='h4'>{title}</Text>
      {
        array.map((item, key) => (
          <Text variant='hintText' key={key} marginTop={1}>
            {item}
          </Text>
        ))
      }
    </Grid>
  );

  const VerifyCpfReportError = ({ field }) => {
    if (reportError[field].hasWrongFormat) {
      return renderCpfReportError(reportError[field].hasWrongFormat, 'Os CPFs abaixo são inválidos:');
    }
    if (reportError[field].hasDuplicate) {
      return renderCpfReportError(reportError[field].hasDuplicate, 'Os CPFs abaixo estão duplicados:');
    }
    return <></>;
  };

  const renderIdentificationReportError = (array, title) => (
    <Grid item xs={12}>
      <Text variant='h4'>{title}</Text>
      {
        array.map((identification, key) => (
          <Text variant='hintText' key={key} marginTop={1}>
            {identification.value}
          </Text>
        ))
      }
    </Grid>
  );

  const VerifyIdentificationReportError = ({ field }) => {
    if (reportError[field].hasWrongMaxLength) {
      return renderIdentificationReportError(reportError[field].hasWrongMaxLength, 'A identificação abaixo é muito grande');
    }
    return <></>;
  };

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant='h1'>Encontramos alguns erros no arquivo.</Text>
      <Text variant='hintText' lineHeight={2}>
        Existem alguns erros no seu arquivo que precisam ser corrigidos.
      </Text>

      <Grid item xs={12} marginTop={3} marginBottom={2}>
        <Grid item xs={12} style={styles.descriptionContainer}>
          <Text variant='h4'>Legenda</Text>
          <DescriptionContainer>
            <DescriptionIconContainer>
              <DescriptionIcon src={warningImg} />
            </DescriptionIconContainer>
            <Text variant='hintText'>
              Indicações em amarelo não impedem o carregamento do arquivo mas é recomendável que o
              máximo de informações estejam preenchidas para melhor funcionamento do sistema.
            </Text>
          </DescriptionContainer>

          <DescriptionContainer>
            <DescriptionIconContainer>
              <DescriptionIcon src={errorImg} />
            </DescriptionIconContainer>
            <Text variant='hintText'>
              Indicações em vermelho impedem o carregamento do arquivo.
            </Text>
          </DescriptionContainer>
        </Grid>

        <Grid item xs={12} marginTop={5}>
          {Object.keys(reportError).map((field, keyField) => (
            <Grid item xs={12} key={keyField} marginTop={3}>
              <Text variant='h4'>{field}</Text>
              {renderFieldValues(field).map((name, keyName) => (
                <Text variant='hintText'
                  key={keyName}
                  style={GlobalStyles.smallMarginTop}
                >
                  {name}
                </Text>
              ))}
              <DescriptionDivider></DescriptionDivider>
              {
                Object.keys(reportError[field]).map((validationType, keyValidationType) => (
                  renderValidationType(field, reportError[field], validationType, keyValidationType)
                ))
              }
              { field === 'CPF' && <VerifyCpfReportError field={field} /> }
              { field === 'Identificação' && <VerifyIdentificationReportError field={field} /> }
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12} textAlign='center'>
        <ButtonComponent
          size='medium'
          onClick={() => history.push('/admin/map-collaborators')}
        >
          Corrigir
        </ButtonComponent>
      </Grid>
    </ContainerFullPage>
  );
}
