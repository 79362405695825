import React from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import Input from '../Input';
import COLORS from '../../../styles/Colors';

export default function DateInput(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <DatePicker
        {...props}
        inputFormat='dd/MM/yyyy'
        renderInput={(params) => (
          <Input
            {...params}
            variant="standard"
            sx={{ svg: { color: COLORS.PRIMARY } }}
            inputProps={{ ...params.inputProps, placeholder: props.placeholderText ? props.placeholderText : 'dd/mm/AAAA' }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
