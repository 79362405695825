import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import ImagePathNoCollaborators from '../../../../assets/images/icon_emptyusers.png';
import { Img, styles } from './styles';
import { ButtonComponent, Text } from '../../../../components';

export default function EmptyList() {
  const history = useHistory();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={styles.container}
    >
      <Grid item xs={12}>
        <Img src={ImagePathNoCollaborators}></Img>
      </Grid>
      <Grid item xs={12}>
        <Text variant='h3' textAlign='center'>Não há nenhum colaborador na sua lista.</Text>
        <Text variant='hintText' textAlign='center'>Comece importando um arquivo.</Text>
      </Grid>

      <Grid item xs={12} style={styles.containerButton}>
        <ButtonComponent
          size='medium'
          onClick={() => history.push('/admin/collaborators-import')}
        >
          Importar arquivo
        </ButtonComponent>
      </Grid>
    </Grid>
  );
}
