import { useState } from 'react';
import classNames from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Icon,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PropTypes from 'prop-types';
import { DrawerHeader, localStyles } from './styles';
import styles from '../../assets/jss/material-dashboard-react/components/sidebarStyle';
import { Text, Link, Logo } from '..';
import COLORS from '../../styles/Colors';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const history = useHistory();
  const resetDispatch = useDispatch();
  const { newComplaints } = useSelector((state) => state.complaintsReducer);
  const { newRequests } = useSelector((state) => state.requestsReducer);
  const Name = useSelector((state) => state.userReducer.decodeToken);
  const classes = useStyles();
  const { routes } = props;
  const container = window !== undefined ? () => window.document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => (
    window.location.href.indexOf(routeName) > -1
  );

  const logout = () => {
    resetDispatch({ type: 'RESET_USER_STATE' });
    history.push('/login');
  };

  const links = (
    <List className={classes.list}>
      <DrawerHeader>
        <Logo mode='light' />
        <IconButton onClick={handleDrawerToggle} sx={localStyles.iconButton}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>

      {
        routes
          .filter((prop) => prop.sidebar === true)
          .map((prop, key) => {
            // eslint-disable-next-line max-len
            const stateListItem = activeRoute(prop.layout + prop.path) ? localStyles.activeListItem : null;

            return (
              <NavLink
                to={prop.layout + prop.path}
                onClick={prop.isExternalPath ? () => window.open(prop.path, '_blank') : null}
                activeClassName="active"
                key={key}
                style={localStyles.navLink}
              >
                <ListItem sx={[localStyles.listItem, stateListItem]}>
                  {
                    prop.icon && (
                      <Icon className={classNames(classes.itemIcon)}>
                        <img src={prop.icon} height={25} width={25} />
                      </Icon>
                    )
                  }

                  <ListItemText
                    sx={localStyles.listItemText}
                    disableTypography={true}
                  >
                    <div style={localStyles.notificationContainer}>
                      {/* Route name */}
                      {props.rtlActive ? prop.rtlName : prop.name}

                      {/* Request's notifications */}
                      { prop.path === '/requests' && newRequests > 0 && (
                        <span style={localStyles.notificationText}>
                          {newRequests}
                        </span>
                      )}

                      {/* Complaint's notifications */}
                      { prop.path === '/complaints' && newComplaints > 0 && (
                        <span style={localStyles.notificationText}>
                          {newComplaints}
                        </span>
                      )}
                    </div>
                  </ListItemText>
                </ListItem>
              </NavLink>
            );
          })
      }

      <ListItem style={localStyles.drawerFooter}>
        <ListItemText>
          <Text variant='hintText' sx={localStyles.userName}>{ Name && Name.user.name }</Text>
          <Text variant='label'>
            <Link onClick={logout} color={COLORS.PRIMARY_LIGHT}>Sair do painel</Link>
          </Text>
        </ListItemText>
      </ListItem>
    </List>
  );

  return (
    <>
      <AppBar position="fixed" sx={localStyles.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={localStyles.iconButton}
          >
            <MenuIcon color='primary' />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={localStyles.mobileDrawer}
      >
        <div className={classes.sidebarWrapper}>{links}</div>
        <div />
      </Drawer>

      <Drawer
        variant="permanent"
        open
        sx={localStyles.webDrawer}
      >
        <div className={classes.sidebarWrapper}>{links}</div>
      </Drawer>
    </>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
};
