import makeStyles from '@mui/styles/makeStyles';
import COLORS from '../../../styles/Colors';

const useStyles = makeStyles({
  selectOptions: {
    minWidth: 145,
    backgroundColor: COLORS.WHITE,
    '&:before': {
      borderColor: COLORS.SECONDARY,
      borderWidth: 1,
    },
    '&:after': {
      borderColor: COLORS.PRIMARY,
      borderWidth: 1,
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: COLORS.PRIMARY,
      borderWidth: 1,
    },
  },
  icon: {
    fill: COLORS.SECONDARY,
  },
  selected: {
    backgroundColor: COLORS.WHITE,
  },
});

export default useStyles;
