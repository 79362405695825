import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CompanyService from '../../services/napoleon/CompanyService';
import Loading from '../../components/Loading';
import FormConfigurationError from './components/FormConfigurationError';
import CollaboratorService from '../../services/napoleon/CollaboratorService';
import EmptyList from './components/EmptyList';
import ListCollaborators from '../ListCollaborators';

export default function Collaborators() {
  const companyService = new CompanyService();
  const collaboratorService = new CollaboratorService();
  const [companyForm, setCompanyForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [hasCollaborators, setHasCollaborators] = useState(true);
  const companyId = useSelector((state) => state.userReducer.decodeToken.user.companyId);
  const formIdDispatch = useDispatch();
  const formDispatch = useDispatch();
  const collaboratorDispatch = useDispatch();

  const listCollaborator = () => {
    collaboratorService.getCollaboratorList(companyId, '1')
      .then((response) => {
        const { data } = response;
        // eslint-disable-next-line max-len
        data.docs.sort((a, b) => a.informations[1].value.localeCompare(b.informations[1].value));
        collaboratorDispatch({
          type: 'FIND_COLLABORATORS',
          collaborators: data.docs,
          rowsPerPage: data.pages,
        });
        setHasCollaborators(data.docs.length > 0);
      });
  };

  const listCompanyForm = () => {
    setLoading(true);
    companyService.getCompanyForm(companyId)
      .then(({ data: form }) => {
        setCompanyForm(form);
        setHasError(false);
        formIdDispatch({ type: 'FIND_FORM_ID', id: form.id });
        formDispatch({ type: 'FIND_FORM', formElement: form.formElements });
      })
      .catch((error) => {
        setHasError(true);
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listCompanyForm();
    listCollaborator();
  }, []);

  if (hasError) return <FormConfigurationError/>;

  if (loading || !companyForm) return <Loading />;

  if (hasCollaborators) return <ListCollaborators />;

  return <EmptyList/>;
}
