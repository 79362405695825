import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';

export const Img = styled('img')({
  height: 80,
  width: 80,
  verticalAlign: 'middle',
  display: 'inline-block',
});

export const styles = createStyles({
  container: {
    minHeight: 100,
    marginTop: 25,
  },
  containerButton: {
    marginTop: 15,
  },
});
