import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import Admin from './layouts/Admin';
import history from './common/history';
import AuthenticatedRoute from './common/AuthenticatedRoute';
import theme from './styles/theme';

function App() {
  const userType = useSelector((state) => state.userReducer.decodeToken.user.userType);
  const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated);
  const sessionExpired = useSelector((state) => state.userReducer.sessionExpired);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <Switch>
              <AuthenticatedRoute
                path="/"
                component={Admin}
                appProps={{ isAuthenticated, userType, sessionExpired }}
              />
            </Switch>
          </ConnectedRouter>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
export default App;
