import { useEffect, useState, useRef } from 'react';
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  Pagination,
  TableRow,
  MenuItem,
  Snackbar,
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { StyledTableRow, StyledTableCell, styles } from './styles';
import '../../styles/table.css';
import { FlexDirectionRowCenter } from '../../styles/components';
import GlobalStyles from '../../styles/GlobalStyles';
import pageIcon from '../../assets/images/iconsQuickAction/employees.png';
import CollaboratorService from '../../services/napoleon/CollaboratorService';
import {
  Alert,
  ButtonComponent,
  EmptyList,
  ImageHeader,
  RadioButton,
  Text,
  Loading,
} from '../../components';
import { SelectInput, Input } from '../../components/Form';
import ImagePathNoCollaborators from '../../assets/images/icon_emptyusers.png';
import InactiveCollaboratorModal from '../Collaborators/components/InactiveCollaboratorModal';

export default function ListCollaborators({ route }) {
  const collaboratorService = new CollaboratorService();
  const companyId = useSelector((state) => state.userReducer.decodeToken.user.companyId);
  const collaborators = useSelector((state) => state.collaboratorReducer.collaborators);
  const rowsPerPage = useSelector((state) => state.collaboratorReducer.rowsPerPage);
  const [filterValue, setFilterValue] = useState('identification');
  const [activeMessageRoute, setActiveMessageRoute] = useState(false);
  const [noCollaborator, setNoCollaborator] = useState(false);
  const [item, setItem] = useState('');
  const [page, setPage] = useState(1);
  const [btnDisable, setBtnDisable] = useState(true);
  /* eslint-disable */
  const [radioStatusLogin, setRadioStatusLogin] = useState([
    { id: 1, text: 'Todos', value: 'all', selected: true },
    { id: 2, text: 'Realizado', value: 'accomplished', selected: false },
    { id: 3, text: 'Nunca realizado', value: 'neverAccomplished', selected: false },
  ]);
  /* eslint-enable */
  const [statusLogin, setStatusLogin] = useState(radioStatusLogin[0].value);
  const didMount = useRef(false);
  const didMountStatusLogin = useRef(false);
  const history = useHistory();
  const collaboratorDispatch = useDispatch();
  const filterOptions = [
    { value: 'identification', text: 'Identificação' },
    { value: 'fullName', text: 'Nome Completo' },
    { value: 'cpf', text: 'CPF' },
  ];
  const [collaboratorToBeInactivated, setCollaboratorToBeInactivated] = useState();
  const [collaboratorToBeRemovedFromList, setRollaboratorToBeRemovedFromList] = useState();
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const listCollaborator = (resetFilter) => {
    setNoCollaborator(true);
    setLoading(true);

    collaboratorService
      .getCollaboratorList(companyId, page, filterValue, item, statusLogin, resetFilter)
      .then((response) => {
        const { data } = response;
        // eslint-disable-next-line max-len
        data.docs.sort((a, b) => a.informations[1].value.localeCompare(b.informations[1].value));
        collaboratorDispatch({
          type: 'FIND_COLLABORATORS',
          collaborators: data.docs,
          rowsPerPage: data.pages,
        });
        setNoCollaborator(data.docs.length <= 0);
      })
      .catch(() => {
        collaboratorDispatch({
          type: 'FIND_COLLABORATORS',
          collaborators: [],
          rowsPerPage: [],
        });
      })
      .finally(() => setLoading(false));
  };

  const findCollaborator = () => {
    setLoading(true);

    collaboratorService.getCollaboratorList(companyId, 1, filterValue, item, statusLogin)
      .then((response) => {
        // eslint-disable-next-line max-len
        response.data.docs.sort((a, b) => a.informations[1].value.localeCompare(b.informations[1].value));
        collaboratorDispatch({
          type: 'FIND_COLLABORATORS',
          collaborators: response.data.docs,
          rowsPerPage: response.data.pages,
        });

        if (response.data.docs.length <= 0) {
          setNoCollaborator(true);
        } else {
          setNoCollaborator(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleClear = () => {
    setItem('');
    setNoCollaborator(false);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const directMessage = (collaborator) => {
    history.push('/admin/create-message', {
      path: 'directMessage',
      collaborator: {
        id: collaborator.id,
        name: collaborator.informations[1].value,
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !btnDisable) {
      findCollaborator();
    }
  };

  const StatusCollaboratorLogin = ({ collaboratorActivated }) => (
    <Text variant='hintText'>{ collaboratorActivated ? radioStatusLogin[1].text : radioStatusLogin[2].text }</Text>
  );

  const getCollaboratorSector = ({ informations }) => {
    const sector = informations.find((information) => information.name === 'Setor');
    if (sector) {
      const { name } = sector.value;
      return name && name !== '' ? name : sector.value;
    }
    return '-';
  };

  useEffect(() => {
    if (route === 'Message') {
      setActiveMessageRoute(true);
      handleClear();
    }
  }, []);

  /**
   * in the first life cycle, it tries to get the collaborators,
   * but they are already in redux, so this useeffect is only valid from the first cycle
   *
   * cleaning the filter involves resetting the page value and resetting the filter type,
   * to ensure that two useeffects aren't called, is checked the page other than 0
   */
  useEffect(() => {
    if (didMount.current && page !== 0) {
      if (item) {
        findCollaborator();
      } else if (noCollaborator) {
        listCollaborator(true);
      }
    }
  }, [filterValue]);

  /**
   * in the first life cycle, it tries to get the collaborators,
   * but they are already in redux, so this useeffect is only valid from the first cycle
   *
   * cleaning the filter involves resetting the page value and resetting the filter type,
   * so it is checked if it is equal to zero to set the default value of 1,
   * which is the first valid page
   */
  useEffect(() => {
    if (didMount.current) {
      if (page === 0) {
        setPage(1);
      } else {
        listCollaborator();
      }
    } else {
      didMount.current = true;
    }
  }, [page]);

  useEffect(() => {
    if (item !== '') {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [item]);

  useEffect(() => {
    if (didMountStatusLogin.current) {
      listCollaborator();
    } else {
      didMountStatusLogin.current = true;
    }
  }, [statusLogin]);

  /**
   * When the contributor is inactivated, the modal component sends the inactivated
   * collaborator back to the list, to indicate that it can be removed
   *
   * Removal is done by listing the current page again,
   * as the backend route only lists active collaborators
   */
  useEffect(() => {
    if (collaboratorToBeRemovedFromList) {
      listCollaborator();
    }
  }, [collaboratorToBeRemovedFromList]);

  if (loading) return <Loading />;

  return (
    <Container>
      <Grid container rowSpacing={1} columnSpacing={1} style={GlobalStyles.justifyContentRowCenter}>

        {/* Header */}
        <Grid item xs={12}>
          <Grid container style={GlobalStyles.justifyContentRowSpaceEvenly}>
            <Grid
              item
              xs={12}
              sm={activeMessageRoute ? 12 : 8}
              style={GlobalStyles.justifyContentRowStart}
            >
              <FlexDirectionRowCenter>
                <ImageHeader src={pageIcon} />
                <Text variant='h2'>{ activeMessageRoute ? 'Selecione o colaborador' : 'Colaboradores' }</Text>
              </FlexDirectionRowCenter>
            </Grid>

            { !activeMessageRoute && (
              <Grid item xs={12} sm={4} style={GlobalStyles.justifyContentRowFlexEnd}>
                <ButtonComponent onClick={() => history.push('/admin/collaborators-import')} size="medium">
                  Importar nova lista
                </ButtonComponent>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Dropdown field to type search text */}
        <Grid
          container item
          xs={12} sm={7} md={7} lg={3.7}
          columnSpacing={1}
          style={GlobalStyles.justifyContentRowStart}
        >
          <Grid item xs={6} sm={6} md={5} lg={6} style={GlobalStyles.justifyContentRowStart}>
            <SelectInput
              id="simple-select-standard"
              value={filterValue}
              onChange={(event) => setFilterValue(event.target.value)}
            >
              {filterOptions.map((option, key) => (
                <MenuItem value={option.value} key={key}>{option.text}</MenuItem>
              ))}
            </SelectInput>
          </Grid>

          <Grid item xs={6} sm={6} md={5} lg={6} style={GlobalStyles.justifyContentRowStart}>
            <Input
              placeholder="Digite para pesquisar"
              value={item}
              onChange={(event) => setItem(event.target.value)}
              onKeyDown={handleKeyDown}
            />
          </Grid>
        </Grid>

        {/* Search/clean buttons */}
        <Grid
          container item
          xs={12} sm={5} md={5} lg={3.5}
          columnSpacing={1}
          sx={styles.containerSearchButtons}
        >
          <Grid item xs={4} style={GlobalStyles.justifyContentRowFlexEnd}>
            <ButtonComponent disabled={btnDisable} onClick={findCollaborator}>
              <SearchOutlinedIcon fontSize="small" /> Buscar&nbsp;
            </ButtonComponent>
          </Grid>

          <Grid item xs={4} style={GlobalStyles.justifyContentRowFlexEnd}>
            <ButtonComponent
              onClick={handleClear}
              variant='outlined'
            >
              Limpar
            </ButtonComponent>
          </Grid>
        </Grid>

        {/* Status login options filter */}
        <Grid container item xs={12} sm={12} md={7} lg={4}>
          <Text variant='hintText' style={styles.messageOptionsFilterSpacing}>Status do login</Text>
          <Grid container style={GlobalStyles.justifyContentRowStart}>
            {
              radioStatusLogin.map((option, key) => (
                <RadioButton
                  text={option.text}
                  selected={option.selected}
                  key={key}
                  onClick={() => {
                    const options = [...radioStatusLogin];
                    if (options[key].selected) return;
                    options[key].selected = true;
                    setStatusLogin(options[key].value);
                    /* eslint-disable */
                    options.map((option, idx) => {
                      if (idx !== key)
                        option.selected = false;
                    })
                    /* eslint-enable */
                    setRadioStatusLogin(options);
                  }}
                />
              ))
            }
          </Grid>
        </Grid>

        {
          noCollaborator ? (
            <EmptyList srcImage={ImagePathNoCollaborators} text='Nenhum colaborador encontrado.' />
          ) : (
            <>
              <TableContainer style={styles.marginTop}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="left">Identificação</StyledTableCell>
                      <StyledTableCell>Nome Completo</StyledTableCell>
                      <StyledTableCell>CPF</StyledTableCell>
                      <StyledTableCell>Setor</StyledTableCell>
                      <StyledTableCell>Status do login</StyledTableCell>
                      { !activeMessageRoute && <StyledTableCell align="right">Ações</StyledTableCell> }
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {collaborators?.map((collaborator) => (
                      <StyledTableRow
                        key={collaborator.id}
                        onClick={() => activeMessageRoute && directMessage(collaborator)}
                        cursor={activeMessageRoute ? 'pointer' : 'unset'}
                      >
                        <TableCell align='left'>{collaborator.informations[0].value}</TableCell>
                        <TableCell align='left'>{collaborator.informations[1].value}</TableCell>
                        <TableCell align='left'>{collaborator.informations[2].value}</TableCell>
                        <TableCell align='left'>{getCollaboratorSector(collaborator)}</TableCell>
                        {/* eslint-disable-next-line max-len */}
                        <TableCell><StatusCollaboratorLogin collaboratorActivated={collaborator.activatedAt} /></TableCell>
                        {!activeMessageRoute && (
                          <TableCell align="right">
                          <DeleteOutlineOutlinedIcon
                            onClick={() => setCollaboratorToBeInactivated(collaborator)}
                            fontSize='small'
                            color='secondary_light'
                            cursor='pointer'
                            sx={GlobalStyles.iconBorderThickness}
                          />
                          </TableCell>)}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={12} style={styles.pagination}>
                <Pagination
                  defaultPage={6}
                  boundaryCount={1}
                  siblingCount={1}
                  page={page}
                  onChange={handleChangePage}
                  count={rowsPerPage}
                  color='primary'
                  size='small'
                />
              </Grid>
            </>
          )
        }
      </Grid>

      <InactiveCollaboratorModal
        collaboratorToBeInactivated={collaboratorToBeInactivated}
        clearCollaboratorToBeInactivated={setCollaboratorToBeInactivated}
        removeCollaboratorInactivateFromList={setRollaboratorToBeRemovedFromList}
        openAlert={setOpenSnackBar}
      />

      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity='success'>
          Colaborador(a) excluído(a) com sucesso!
        </Alert>
      </Snackbar>
    </Container>
  );
}
