import EastRoundedIcon from '@mui/icons-material/EastRounded';
import PropTypes from 'prop-types';
import Text from '../../../../components/Text';
import {
  Container,
  Img,
  ComingSoon,
  styles,
} from './styles';

const HomeButton = ({
  imagePath,
  title,
  text,
  onClick,
  comingSoon,
}) => (
  <Container onClick={onClick} disabled={comingSoon}>
    { comingSoon && (
      <ComingSoon>
        <Text variant='label'>Em breve</Text>
      </ComingSoon>
    )}

    {
      imagePath ? <Img src={imagePath} /> : <Text variant='h1' align='left'>{title}</Text>
    }

    <Text variant='h4' align='left' sx={styles.text}>
      {text}
    </Text>

    <EastRoundedIcon fontSize='small' color='primary' />
  </Container>
);

HomeButton.propTypes = {
  imagePath: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  comingSoon: PropTypes.bool,
};

export default HomeButton;
