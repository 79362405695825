import styled from '@mui/system/styled';
import ButtonBase from '@mui/material/ButtonBase';
import createStyles from '@mui/styles/createStyles';
import COLORS from '../../../../styles/Colors';

export const Container = styled(ButtonBase)((props) => ({
  width: '100%',
  height: 180,
  backgroundColor: props.disabled ? COLORS.LIGHT : COLORS.WHITE,
  paddingLeft: 15,
  paddingRight: 10,
  marginRight: 20,
  boxShadow: '0 2px 10px 0 rgb(0 0 0 / 10%)',
  borderWidth: 10,
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'start',
  alignItems: 'start',
  flexDirection: 'column',
  '&:hover': {
    backgroundColor: COLORS.LIGHT,
  },
}));

export const Img = styled('img')({
  height: 35,
  width: 35,
});

export const ComingSoon = styled('div')({
  position: 'absolute',
  top: 10,
  right: 10,
});

export const styles = createStyles({
  text: {
    paddingTop: 2,
    marginBottom: 1,
  },
});
