import React, { useState } from 'react';
import { Modal, Box } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import PropTypes from 'prop-types';
import { ButtonComponent, Text } from '../../../../components';
import GlobalStyles from '../../../../styles/GlobalStyles';
import styles from './styles';
import SectorsService from '../../../../services/napoleon/SectorsService';

export default function ModalDeleteSector(props) {
  const {
    open,
    handleOpenModal,
    handleSubmitData,
    selectedSector,
    setSelectedSector,
    handleShowToast,
  } = props;
  const sectorsService = new SectorsService();
  const [loading, setLoading] = useState(false);

  const closeModal = ({ submit }) => {
    setLoading(false);
    if (submit) {
      handleSubmitData((prevValue) => !prevValue);
    }
    setSelectedSector(null);
    handleOpenModal(false);
  };

  const handleSubmit = () => {
    setLoading(true);

    sectorsService.inactiveSector(selectedSector.id)
      .then(() => {
        handleShowToast({ type: 'success', text: 'Setor excluído com sucesso!' });
        closeModal({ submit: true });
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => setLoading(false));
  };

  if (selectedSector) {
    return (
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal} textAlign='center'>
          <WarningAmberRoundedIcon color='primary' style={styles.warningIcon} />

          <Text variant="h2">Excluir Setor</Text>
          <Text variant='paragraph' marginBottom={2} marginTop={2}>
            Tem certeza que deseja excluir o setor <br/>
            <b>{ selectedSector.name }</b>?
          </Text>
          <Text variant='hintText' marginBottom={2}>
            Ao excluir esse setor os colaboradores ficarão sem setor designado.
          </Text>

          <div style={GlobalStyles.justifyContentRowSpaceEvenly}>
            <ButtonComponent onClick={closeModal} color='primary_light'>
              Fechar
            </ButtonComponent>
            <ButtonComponent onClick={handleSubmit} color='primary' loading={loading}>
              Excluir
            </ButtonComponent>
          </div>
        </Box>
      </Modal>
    );
  }

  return <></>;
}

ModalDeleteSector.propTypes = {
  open: PropTypes.bool,
  handleOpenModal: PropTypes.func,
  handleSubmitData: PropTypes.func,
  selectedSector: PropTypes.object,
  setSelectedSector: PropTypes.func,
  handleShowToast: PropTypes.func.isRequired,
};
