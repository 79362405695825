import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { Box, Grid, Modal, MenuItem } from '@mui/material';
import AWSService from '../../services/napoleon/AWSService';
import MessageService from '../../services/napoleon/MessageService';
import RequestRHService from '../../services/napoleon/RequestRHService';
import ComplaintsService from '../../services/napoleon/ComplaintsService';
import SectorsService from '../../services/napoleon/SectorsService';
import {
  UploadLabel,
  UploadInput,
  Img,
  AttachmentContainer,
  AttachmentRemove,
  styles,
} from './styles';
import iconError from '../../assets/images/icons/icon_error.png';
import { Text, ButtonComponent } from '../../components';
import {
  Input,
  DateInput,
  SelectInput,
  MultipleSelectInput,
} from '../../components/Form';
import COLORS from '../../styles/Colors';
import GlobalStyles from '../../styles/GlobalStyles';
import { ContainerFullPage } from '../../styles/components';

function DirectMessages(props) {
  const path = props.location.state?.path;
  const calledByDirectMessage = path === 'directMessage';
  const collaborator = props.location.state?.collaborator;
  const requestToUpdate = props.location.state?.requestToUpdate;
  const complaintToUpdate = props.location.state?.complaintToUpdate;
  const pageTitle = calledByDirectMessage ? 'Nova Mensagem Direta' : 'Nova Postagem no Mural';
  const sendMessageServices = new MessageService();
  const requestRHService = new RequestRHService();
  const complaintsService = new ComplaintsService();
  const sectorsService = new SectorsService();
  const admin = useSelector((state) => state.userReducer.decodeToken);
  const complaintsDispatch = useDispatch();
  const requestsDispatch = useDispatch();
  const [titleError, setTitleError] = useState(false);
  const [senderError, setSenderError] = useState(false);
  const [sender, setSender] = useState(admin?.user.name);
  const [title, setTitle] = useState(undefined);
  const [message, setMessage] = useState();
  const [expiryDate, setExpiryDate] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const awsService = new AWSService();
  const uploadInput = useRef(null);
  const uploadInputAttachment = useRef(null);
  const [fileThumbnail, setFileThumbnail] = useState([]);
  const [filesAttach, setfilesAttach] = useState([]);
  const [errorImage, setErrorImage] = useState(false);
  const [attachmentsHasValidListSize, setAttachmentsHasValidListSize] = useState(true);
  const [attachmentsHasValidFileType, setAttachmentsHasValidFileType] = useState(true);
  const [loading, setLoading] = useState(false);
  const categoryDefaultValue = 'none';
  const [category, setCategory] = useState(categoryDefaultValue);
  const [sectors, setSectors] = useState([]);
  const sectorsDefaultOption = 'Todos';
  const [sectorOptions, setSectorOptions] = useState([sectorsDefaultOption]);
  const handleChangeCategory = (event) => setCategory(event.target.value);
  const categoryOptions = calledByDirectMessage ? ['Banco de Horas', 'Escala', 'Holerite'] : ['Escala'];

  const getSignedUrl = async (file) => {
    let signedData = null;
    await awsService.getSignedUrl(file).then((response) => {
      const { data } = response;
      signedData = data;
    });
    return signedData;
  };

  const uploadFile = async (file, signedUrl) => {
    await awsService.uploadFile(file, signedUrl);
  };

  const uploadFiles = async () => {
    if (fileThumbnail.length > 0) {
      const { key, signedUrl } = await getSignedUrl(fileThumbnail[0]);
      await uploadFile(fileThumbnail[0], signedUrl);
      return key;
    }
    return null;
  };

  const uploadFilesAttachment = async () => {
    if (filesAttach.length > 0) {
      const documentsKeys = await Promise.all(
        Array.from(filesAttach).map(async (file) => {
          const { key, signedUrl } = await getSignedUrl(file);
          await uploadFile(file, signedUrl);
          return {
            key,
            fileName: file.name,
          };
        }),
      );
      return documentsKeys;
    }
    return null;
  };

  const handleSubmit = async () => {
    const attachmentsFiles = await uploadFilesAttachment();
    const thumbnailAttachment = await uploadFiles();
    let send = {};

    if (thumbnailAttachment) {
      send = Object.assign(send, { thumbnail: thumbnailAttachment });
    }
    if (attachmentsFiles && attachmentsFiles.length) {
      send = Object.assign(send, { attachments: attachmentsFiles });
    }
    if (message) {
      send = Object.assign(send, { description: message });
    }
    if (expiryDate) {
      send = Object.assign(send, { expirectedAt: expiryDate });
    }
    if (category !== categoryDefaultValue) {
      send = Object.assign(send, { category });
    }
    if (!calledByDirectMessage && sectorOptions && sectorOptions[0] !== 'Todos') {
      const sectorsIds = [];
      sectorOptions.forEach((option) => {
        const finded = sectors.find((sector) => sector.name === option);
        sectorsIds.push(finded.id);
      });
      send = Object.assign(send, { sectorsIds });
    }

    if (calledByDirectMessage) {
      send = Object.assign(send, {
        subject: title,
        senderName: sender,
        creatorUserId: admin?.user.id,
        messageRecipient: {
          collaboratorId: collaborator?.id,
        },
      });
    } else {
      send = Object.assign(send, {
        subject: title,
        senderName: sender,
        creatorUserId: admin?.user.id,
        messageRecipient: {
          companyId: admin.user.companyId,
        },
      });
    }

    sendMessageServices
      .sendMessage(send)
      .then(() => {
        setLoading(false);

        if (requestToUpdate) {
          requestRHService.resolveRequest(requestToUpdate, requestsDispatch)
            .catch((error) => {
              throw error;
            });
        } else if (complaintToUpdate) {
          complaintsService.resolveComplaint(complaintToUpdate, complaintsDispatch)
            .catch((error) => {
              throw error;
            });
        }

        history.push('/admin/success-collaborators-import-validate', {
          title: 'Sua mensagem foi enviada com sucesso!',
          buttonText: 'Retornar ao início',
          redirectTo: path,
        });
      })
      .catch(() => {
        setLoading(false);
        setOpen(true);
        setLoading(false);
      });
  };

  const validation = () => {
    if (loading) return;

    if (sender === '') {
      setSenderError(true);
      setLoading(false);
    }
    if (title === '' || title === undefined) {
      setTitleError(true);
      setLoading(false);
    }
    if (sender !== '' && title !== '' && title !== undefined) {
      setLoading(true);
      handleSubmit();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteItem = (index) => {
    setFileThumbnail(fileThumbnail.filter((item, i) => index !== i));
  };

  const handleDeleteAttachment = (index) => {
    setfilesAttach(filesAttach.filter((item, i) => index !== i));
  };

  const handleValidationImg = (event) => {
    const size = Array.from(event.target.files).map((item) => item.size);
    if (size < 5000000) {
      setFileThumbnail(Array.from(event.target.files));
      setErrorImage(false);
    }
    if (size > 5000000) {
      setErrorImage(true);
    }
  };

  const handleValidationAttachment = (event) => {
    const itens = Array.from(event.target.files).map((item) => item);
    const validateFileSize = itens.filter((item) => item.size > 10000000);

    setAttachmentsHasValidListSize(true);
    setAttachmentsHasValidFileType(true);

    if (itens.length > 10) {
      setAttachmentsHasValidListSize(false);
    }

    if (validateFileSize.length > 0) {
      setAttachmentsHasValidFileType(false);
    }

    if (itens.length <= 10 && validateFileSize.length <= 0) {
      setfilesAttach(Array.from(event.target.files));
    }
  };

  const HelperText = ({ children }) => <Text variant='hintTextLabel' color={COLORS.RED}>{children}</Text>;

  useEffect(() => {
    if (!calledByDirectMessage) {
      sectorsService.getSectors(admin.user.companyId, 1, 1000)
        .then((response) => {
          const { data } = response;
          setSectors(data.sectors);
        })
        .catch((error) => {
          throw error;
        });
    }
  }, []);

  useEffect(() => {
    if (sender !== '') {
      setSenderError(false);
    }
  }, [sender]);

  useEffect(() => {
    if (title !== '') {
      setTitleError(false);
    }
  }, [title]);

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant='h1'>{ pageTitle }</Text>
      <Text variant='label' style={styles.requiredFields}>Campos marcados com * são obrigatórios.</Text>

      { calledByDirectMessage && <Text variant='paragraph'>Para: {collaborator.name || 'Nome não cadastrado'}</Text> }

      <Text variant='h4' style={styles.inputLabel}>REMETENTE*</Text>
      <Input
        placeholder="Admin"
        value={sender}
        error={senderError || sender === ''}
        style={styles.input}
        onChange={(event) => setSender(event.target.value.slice(0, 25))}
      />
      {senderError || sender === '' ? <HelperText>Campo obrigatório</HelperText> : <div />}

      <Text variant='h4' style={styles.inputLabel}>TÍTULO*</Text>
      <Input
        placeholder="Insira o título aqui"
        error={titleError || title === ''}
        value={title}
        style={styles.input}
        onChange={(event) => setTitle(event.target.value)}
      />
      {titleError || title === '' ? <HelperText>Campo obrigatório</HelperText> : <div />}

      <Text variant='h4' style={styles.inputLabel}>MENSAGEM</Text>
      <Input
        multiline={true}
        rows={6}
        value={message}
        placeholder='Insira a mensagem aqui'
        style={styles.input}
        onChange={(event) => setMessage(event.target.value.slice(0, 10000))}
      />

      <Grid item xs={12}>
        <Text variant='h4' style={styles.inputLabel}>CATEGORIA</Text>
        <SelectInput
          labelId="simple-select-standard-label"
          id="simple-select-standard"
          defaultValue={categoryDefaultValue}
          value={category}
          onChange={handleChangeCategory}
        >
          <MenuItem value={categoryDefaultValue}><em>Nenhuma</em></MenuItem>
          {categoryOptions.map((item, key) => (
            <MenuItem value={item} key={key}>{item}</MenuItem>
          ))}
        </SelectInput>
      </Grid>

      {
        !calledByDirectMessage && (
          <Grid item xs={12}>
            <Text variant='h4' style={styles.inputLabel}>SETORES</Text>
            <MultipleSelectInput
              array={sectors}
              value={sectorOptions}
              setValue={setSectorOptions}
              firstOption={sectorsDefaultOption}
            />
          </Grid>
        )
      }

      <Text variant='h4' style={styles.inputLabel}>IMAGEM</Text>
      <Text variant='label'>Arquivos aceitos: jpg, png, gif (máx. 5mb)</Text>
      { errorImage && (
        <HelperText>A imagem deve ter até 5 mb e o formato PNG, JPG ou GIF</HelperText>
      )}
      {fileThumbnail.map((item, index) => (
        <AttachmentContainer key={index}>
          <Text variant='hintTextLabel' style={styles.attachmentName}>{item?.name}</Text>
          <AttachmentRemove onClick={() => handleDeleteItem(index)}>Remover</AttachmentRemove>
        </AttachmentContainer>
      ))}
      <Grid item xs={12}>
        <UploadLabel htmlFor="collaborators-file">Selecionar arquivo</UploadLabel>
        <UploadInput
          id="collaborators-file"
          contentDisposition="auto"
          inputRef={uploadInput}
          autoUpload={false}
          onChange={(event) => handleValidationImg(event)}
          accept=".gif, .jpg, .png"
        />
      </Grid>

      <Text variant='h4' style={styles.inputLabel}>ANEXO</Text>
      <Text variant='label'>Arquivos aceitos: pdf, png, jpg, xlsx, xls, docx (máx. 10mb)</Text>
      {!attachmentsHasValidFileType ? (
        <HelperText>
          O anexo deve ter até 10 mb e o formato PDF, PNG, JPG, XLSX, XLS ou DOCX
        </HelperText>
      ) : (
        <div />
      )}
      {!attachmentsHasValidFileType && !attachmentsHasValidListSize ? <br /> : <div />}
      {!attachmentsHasValidListSize ? (
        <HelperText>Você pode enviar até 10 anexos</HelperText>
      ) : (
        <div />
      )}
      {filesAttach.map((item, index) => (
        <AttachmentContainer key={index}>
          <Text variant='hintTextLabel' style={styles.attachmentName}>{item?.name}</Text>
          <AttachmentRemove onClick={() => handleDeleteAttachment(index)}>Remover</AttachmentRemove>
        </AttachmentContainer>
      ))}
      <Grid item xs={12}>
        <UploadLabel htmlFor="collaborators-files">Selecionar arquivo</UploadLabel>
        <UploadInput
          id="collaborators-files"
          contentDisposition="auto"
          inputRef={uploadInputAttachment}
          onChange={(event) => handleValidationAttachment(event)}
          autoUpload={false}
          multiple
          accept=".pdf, .jpg, .png, .xlsx, .xls, .docx"
        />
      </Grid>

      {calledByDirectMessage ? (
        null
      ) : (
        <>
          <Text variant='h4' style={styles.inputLabel}>DATA DE EXPIRAÇÃO</Text>
          <DateInput
            value={expiryDate}
            onChange={(selectedDate) => setExpiryDate(selectedDate)}
            placeholderText='Insira a data'
            disablePast
          />
        </>
      )}

      <Grid item xs={12} style={styles.submitButton}>
        <ButtonComponent
          size="medium"
          onClick={validation}
          loading={loading}
        >
          Postar
        </ButtonComponent>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Box style={styles.modalBox}>
          <Img src={iconError} />
          <Text variant='h1' style={styles.modalTitle} textAlign='center'>Ops, algo deu errado</Text>
          <Text variant='hintText' style={styles.modalDescription} textAlign='center'>
            Tente novamente e se o problema persistir entre em contato com nosso time.
          </Text>
          <ButtonComponent
            onClick={() => setOpen(false)}
            size='medium'
          >
            Tente novamente
          </ButtonComponent>
        </Box>
      </Modal>
    </ContainerFullPage>
  );
}
export default DirectMessages;
