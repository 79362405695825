import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import COLORS from '../../styles/Colors';
import theme from '../../styles/theme';

export const StyledTableRow = styled(TableRow)((props) => ({
  '&:hover': {
    backgroundColor: COLORS.LIGHT,
    cursor: props.cursor,
  },
}));

export const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    ...theme.typography.h5,
    backgroundColor: COLORS.LIGHT,
  },
});

export const styles = createStyles({
  marginTop: {
    marginTop: 15,
  },
  containerSearchButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: { xs: 'flex-end', sm: 'flex-start' },
    alignItems: 'flex-end',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
  },
});
