import React from 'react';
import Select from '@mui/material/Select';
import useStyles from './styles';

export default function SelectInput(props) {
  const classes = useStyles();
  return (
    <Select
      variant="standard"
      className={classes.selectOptions}
      classes={{ select: classes.selected }}
      inputProps={{
        classes: { icon: classes.icon },
      }}
      {...props}
    >
      {props.children}
    </Select>
  );
}
