export const FIND_IS_AUTHENTICATED = 'FIND_IS_AUTHENTICATED';
export const FIND_TOKEN = 'FIND_TOKEN';
export const FIND_TOKEN_DECODE = 'FIND_TOKEN_DECODE';
export const RESET_USER_STATE = 'RESET_USER_STATE';
export const FIND_FORM_ID = 'FIND_FORM_ID';
export const HANDLER_COLLABORATORS_FILE_ERROR = 'HANDLER_COLLABORATORS_FILE_ERROR';
export const FIND_FORM_DATA = 'FIND_FORM_DATA';
export const HANDLER_LIST_PREVIEW = 'HANDLER_LIST_PREVIEW';
export const HANDLER_FILE = 'HANDLER_FILE';
export const HANDLER_SELECTED_FIELDS = 'HANDLER_SELECTED_FIELDS';
export const FIND_FORM = 'FIND_FORM';
export const FIND_IMPORT_ERRORS = 'FIND_IMPORT_ERRORS';
export const FIND_COLLABORATORS = 'FIND_COLLABORATORS';
export const FIND_MESSAGES = 'FIND_MESSAGES';
export const UPDATE_RENDERED_MESSAGE = 'UPDATE_RENDERED_MESSAGE';
export const FIND_COMPANY_STATISTICS = 'FIND_COMPANY_STATISTICS';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const FIND_COMPLAINTS = 'FIND_COMPLAINTS';
export const RESOLVE_COMPLAINT = 'RESOLVE_COMPLAINT';
export const FIND_REQUESTS = 'FIND_REQUESTS';
export const RESOLVE_REQUEST = 'RESOLVE_REQUEST';
