import styled from '@mui/system/styled';
import Container from '@mui/material/Container';
import createStyles from '@mui/styles/createStyles';

export const ContainerMain = styled(Container)({
  height: '100%',
  width: '100%',
});

export const ContainerButtons = styled('div')({
  marginTop: 20,
  display: 'flex',
  flexDirection: 'row',
});

const gridItem = {
  marginTop: 20,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

export const styles = createStyles({
  spacing: {
    marginTop: 5,
    marginBottom: 4,
  },
  spacingBottom: {
    marginBottom: 10,
  },
  containerLastMessages: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridItem: {
    ...gridItem,
  },
  containerGridRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  gridItemRequestsComplaints: {
    ...gridItem,
    marginTop: 0,
  },
  containerGridReadRate: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 200,
    paddingLeft: 30,
  },
});
