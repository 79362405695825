import styled from '@mui/system/styled';
import createStyles from '@mui/styles/createStyles';

export const UploadInput = styled('input')({
  display: 'none',
});

export const Img = styled('img')({
  maxWidth: '100%',
  verticalAlign: 'middle',
  display: 'inline-block',
  marginTop: 10,
});

export const styles = createStyles({
  requiredField: {
    marginTop: 10,
  },
  informationContainer: {
    marginTop: 30,
  },
});
