import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select, Forms } from './styles';
import { ButtonComponent, Text } from '../../../../components';
import { ContainerFullPage } from '../../../../styles/components';
import GlobalStyles from '../../../../styles/GlobalStyles';

export default function MapCollaborators() {
  const hist = useHistory();
  const headers = useSelector((state) => state.formReducer.file_collaborators.headers);
  const collaborators = useSelector((state) => state.formReducer.file_collaborators.collaborators);
  const formElements = useSelector((state) => state.formReducer.formElement);
  const selectedFieldsFromState = useSelector((state) => state.formReducer.selectedFields);
  let previousSelectedValues = '';
  const listPreview = [];
  const selectedDispatch = useDispatch();
  const listPreviewDispatch = useDispatch();
  const [optionsSelect, setOptionsSelect] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);

  const handleDisableBtn = () => {
    setBtnDisable(selectedFields.filter((field) => field.fieldColumnName === '' && field.required).length > 0);
  };

  const hasSelectedValue = () => {
    setTimeout(() => {
      const selects = document.getElementsByTagName('SELECT');
      Array.from(selects).map((select) => {
        const newSelect = select;
        Array.from(select.options).map((option, indexOption) => {
          const newOption = option;
          selectedFieldsFromState.map((selectedField) => {
            if (
              select.getAttribute('data-header').toString()
                === selectedField.fieldColumnName.toString()
              && selectedField.formFieldName === option.value
            ) {
              newOption.style.display = 'flex';
              newSelect.selectedIndex = indexOption;
            } else if (newOption.value !== 'Não mapear') {
              newOption.style.display = 'none';
            }
            return selectedField;
          });
          return newOption;
        });
        return newSelect;
      });
      handleDisableBtn();
    }, 500);
  };

  /** this function transforms the formFieldList into an array of objects for the select options. */
  const mountSelectOptionsAndSelectedFields = () => {
    const options = [];
    const fields = [];

    formElements.map((formElement) => {
      if (formElement.active === true) {
        options.push({ value: formElement.name, label: formElement.name });
        fields.push({ formFieldName: formElement.name, fieldColumnName: '', required: formElement.required });
      }
      return formElement;
    });

    if (optionsSelect.length <= 0) {
      setOptionsSelect(options);
    }
    if (selectedFields.length <= 0) {
      if (selectedFieldsFromState.length > 0) {
        setSelectedFields(selectedFieldsFromState);
        hasSelectedValue();
        handleDisableBtn();
      } else {
        setSelectedFields(fields);
      }
    }
  };

  /** this function is called selectet and always fetches values that were selected before. */
  const handleFocus = (event) => {
    previousSelectedValues = event.target.value;
  };

  /** this function is called handleItems. */
  const itemHasSelected = (item) => selectedFields.filter((prop) => prop.formFieldName === item && prop.fieldColumnName === '')
    .length > 0;

  /** this function prevents select elements from being shown more than once. */
  const handleValidation = (event) => {
    const selects = document.getElementsByTagName('SELECT');

    Array.from(selects).map((select) => {
      select.blur();
      if (event.target.id !== select.id) {
        Array.from(select.options).map((option) => {
          const newOption = option;

          if (newOption.value !== 'Não mapear') {
            if (itemHasSelected(newOption.value) || newOption.value === previousSelectedValues) {
              newOption.style.display = 'flex';
            } else {
              newOption.style.display = 'none';
            }
          } else {
            selectedFields.map((prop) => {
              const newProp = prop;
              if (prop.formFieldName === previousSelectedValues) {
                newProp.fieldColumnName = '';
              }
              return newProp;
            });
          }
          return newOption;
        });
      }
      return select;
    });

    Array.from(event.target.options).map((option) => {
      const newOption = option;
      if (option.value === previousSelectedValues) {
        newOption.style.display = 'flex';
      }
      return newOption;
    });
  };

  /** this function observe the changes of the selects. */
  const handleChange = (event, header) => {
    selectedFields
      .filter((prop) => prop.formFieldName === event.target.value)
      .map((field) => {
        const fieldUpdated = field;
        fieldUpdated.fieldColumnName = header;
        return field;
      });
    handleValidation(event);
    handleDisableBtn();
  };

  /** this function maps the options of selects. */
  const renderItens = () => {
    const render = optionsSelect.map((prop, index) => (
      <option key={index} value={prop.label}>
        {prop.label}
      </option>
    ));
    return render;
  };

  /** this function prepares data to be used in page PreviewList and server */
  const handleSubmit = () => {
    selectedFields.map((field) => {
      collaborators.map((collaborator) => {
        if (collaborator[field.fieldColumnName]) {
          listPreview.push({
            field: field.fieldColumnName,
            value: collaborator[field.fieldColumnName],
          });
        }
        return null;
      });
      return null;
    });
    listPreviewDispatch({ type: 'HANDLER_LIST_PREVIEW', listPreview });
    selectedDispatch({ type: 'HANDLER_SELECTED_FIELDS', selectedFields });
    hist.push('/admin/preview-list');
  };

  mountSelectOptionsAndSelectedFields();

  return (
    <ContainerFullPage sx={GlobalStyles.containerFullPageSize}>
      <Text variant='h1'>Mapeie as colunas do arquivo</Text>
      <Text variant='hintText' style={GlobalStyles.smallMarginTop}>
        Selecione as colunas correspondentes utilizando os seletores abaixo.
      </Text>

      {headers.map((header, index) => (
        <Forms
          container
          key={index}
          last={index === headers.length - 1 ? 1 : 0}
          rowSpacing={2}
        >
          <Grid item xs={12} sm={5}>
            <Text variant='h4'>Nome da coluna</Text>
            <Text variant='hintText'>{header}</Text>

            <Text variant='h4' style={GlobalStyles.smallMarginTop}>Dados nessa coluna</Text>
            {collaborators.map((collaborator, key) => (
              <Text variant='hintText' key={key} lineHeight={1.5}>{collaborator[header]}</Text>
            ))}
          </Grid>

          <Grid item xs={12} sm={7}>
            <Text variant='h4'>Selecione a coluna correspondente</Text>
            <Select
              id={`select-${index}`}
              data-header={header}
              onChange={(event) => handleChange(event, header)}
              onFocus={(event) => handleFocus(event)}
              defaultValue={'Não mapear'}
            >
              <>{renderItens()}</>
              <option value="Não mapear">Não Mapear</option>
            </Select>
          </Grid>
        </Forms>
      ))}

      <Grid item xs={12} textAlign='center'>
        <ButtonComponent
          size='medium'
          disabled={btnDisable}
          onClick={() => handleSubmit()}
        >
          Próximo passo
        </ButtonComponent>
      </Grid>
    </ContainerFullPage>
  );
}
