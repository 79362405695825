import React from 'react';
import {
  Switch, Route, Redirect, useLocation, useHistory,
} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Toolbar } from '@mui/material';
import Sidebar from '../components/Sidebar/Sidebar';
import Text from '../components/Text';
import routes from '../routes';
import styles from '../assets/jss/material-dashboard-react/layouts/adminStyle';
import arrowBackImg from '../assets/images/icons/arrow_back.png';
import { BackButton, MainPanel, localStyles } from './styles';
import 'bootstrap/dist/css/bootstrap.min.css';

function switchRoutes() {
  return (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === '/admin') {
          return (
            <Route path={prop.layout + prop.path} exact component={prop.component} key={key} />
          );
        }
        return null;
      })}
      <Redirect from="/admin" to="/admin/home" />
      <Redirect from="/" to="/admin/home" />
    </Switch>
  );
}

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  let hasNoSpecificRoute = false;
  const routesWithoutBackButtonOption = [
    '/admin/success-collaborators-import-validate',
    '/admin/error-collaborators-import-validate',
  ];
  const location = useLocation();
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const activeRoute = (routeName) => window.location.href.indexOf(routeName) > -1;
  const history = useHistory();

  /**
   * Checks if the current route is in the route definition
   * and then looks for the corresponding goBackToPage property.
   */
  const goBackPage = () => {
    let goBackToPage = '/admin/home';
    routes.map((route) => {
      const routePathName = `${route.layout}${route.path}`;
      if (rest.location.state && rest.location.state.path === 'mural') {
        goBackToPage = '/admin/mural';
      } else if (rest.location.pathname === '/admin/create-message') {
        hasNoSpecificRoute = true;
      } else if (routePathName === location.pathname) {
        goBackToPage = route.goBackToPage;
      }

      return route;
    });
    if (hasNoSpecificRoute) {
      rest.history.goBack();
    } else {
      history.push(goBackToPage);
    }
  };

  const activeFullPage = () => {
    let isFullPage = false;
    routes.map((route) => {
      if (activeRoute(route.layout + route.path) && route.fullPage) {
        isFullPage = true;
      }
      return route;
    });

    return isFullPage;
  };

  if (activeFullPage()) {
    return (
      <Grid container>
        {!routesWithoutBackButtonOption.includes(location.pathname) ? (
          <Grid item md={12} style={localStyles.headerBackButton}>
            <BackButton onClick={() => goBackPage()}>
              <img src={arrowBackImg} height={30} width={30} style={localStyles.backIcon} />
              <Text variant='h4' color='secondary'>Retornar</Text>
            </BackButton>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <div className={classes.container}>{switchRoutes()}</div>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <Sidebar routes={routes} {...rest} />

      <MainPanel component="main" sx={localStyles.mainPanelResize} ref={mainPanel}>
        <Toolbar sx={localStyles.toolbar}/>
        {switchRoutes()}
      </MainPanel>
    </div>
  );
}
