import { Grid, Paper, Container } from '@mui/material';
import { CollaboratorAdhesionGraph, Text, ImageHeader } from '../../components';
import { ContainerTitle, styles } from './styles';
import StatisticsPageIcon from '../../assets/images/iconsQuickAction/statistics.png';

const Statistics = () => (
  <Container>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container style={styles.justifyContentRowSpaceEvenly}>
          <Grid item xs={12} style={styles.justifyContentColumnStart}>
            <ContainerTitle>
              <ImageHeader src={StatisticsPageIcon} />
              <Text variant='h2'>Estatísticas</Text>
            </ContainerTitle>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={styles.statisticsContent}>
        <Grid item xs={12}>
          <Paper style={styles.paper}>
            <Grid item xs={12}>
              <Text variant='h3'>Adesão de colaboradores</Text>
            </Grid>
            <CollaboratorAdhesionGraph />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default Statistics;
