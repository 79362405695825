import dashboardIcon from './assets/images/iconsSideBar/home.png';
import benefitsCardIcon from './assets/images/iconsSideBar/card.png';
import muralIcon from './assets/images/iconsSideBar/board.png';
import employeesIcon from './assets/images/iconsSideBar/employees.png';
import stats from './assets/images/iconsSideBar/statistics.png';
import directMessagesIcon from './assets/images/iconsSideBar/message.png';
import requestsIcon from './assets/images/iconsSideBar/request.png';
import complaintsIcon from './assets/images/iconsSideBar/complaint.png';
import sectorsIcon from './assets/images/iconsSideBar/sector.png';

import HomePage from './views/Home';
import Mural from './views/Mural';
import DirectMessage from './views/DirectMessages';
import Statistics from './views/Statistics';
import NoPermission from './views/NoPermission';
import Collaborators from './views/Collaborators';
import Instructions from './views/Collaborators/components/ImportCollaborators/components/Instructions';
import ReportErrorFile from './views/Collaborators/components/ImportCollaborators/components/ReportErrorFile';
import SuccessImportFileMessage from './views/Collaborators/components/ImportCollaborators/components/SuccessImportFileMessage';
import ErrorImportFileMessage from './views/Collaborators/components/ImportCollaborators/components/ErrorImportFileMessage';
import MapCollaborators from './views/Collaborators/components/MapCollaborators';
import PreviewList from './views/Collaborators/components/PreviewList';
import ListCollaborators from './views/ListCollaborators';
import Requests from './views/Requests';
import Complaints from './views/Complaints';
import Sectors from './views/Sectors';

const dashboardRoutes = [
  {
    path: '/home',
    name: 'Home',
    icon: dashboardIcon,
    component: HomePage,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: 'https://empresa.volus.com',
    isExternalPath: true,
    name: 'Cartão de benefícios',
    icon: benefitsCardIcon,
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/mural',
    name: 'Mural',
    icon: muralIcon,
    component: Mural,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/direct-message',
    name: 'Mensagens diretas',
    component: Mural,
    icon: directMessagesIcon,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/requests',
    name: 'Solicitações',
    icon: requestsIcon,
    component: Requests,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/complaints',
    name: 'Denúncias',
    icon: complaintsIcon,
    component: Complaints,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/statistics',
    name: 'Estatísticas',
    icon: stats,
    component: Statistics,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/collaborators',
    name: 'Colaboradores',
    icon: employeesIcon,
    component: Collaborators,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/ListCollaborators',
    name: 'ListCollaborators',
    icon: employeesIcon,
    component: ListCollaborators,
    layout: '/admin',
    sidebar: false,
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/collaborators-import',
    name: 'Importação de Colaboradores',
    icon: '',
    component: Instructions,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/collaborators',
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/create-message',
    name: 'Criar Mensagem',
    icon: '',
    component: DirectMessage,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/report-error-collaborators-import',
    name: 'Relatório de erro na validação dos Colaboradores',
    icon: '',
    component: ReportErrorFile,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/map-collaborators',
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/success-collaborators-import-validate',
    name: 'Sucesso na validação de importação dos Colaboradores',
    icon: '',
    component: SuccessImportFileMessage,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/error-collaborators-import-validate',
    name: 'Erro na validação de importação dos Colaboradores',
    icon: '',
    component: ErrorImportFileMessage,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/access-denied',
    name: '',
    icon: '',
    component: NoPermission,
    layout: '/admin',
    sidebar: false,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
  {
    path: '/map-collaborators',
    name: '',
    icon: '',
    component: MapCollaborators,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/collaborators-import',
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/preview-list',
    name: '',
    icon: '',
    component: PreviewList,
    layout: '/admin',
    sidebar: false,
    fullPage: true,
    goBackToPage: '/admin/map-collaborators',
    access: ['MASTER', 'HUMAN_RESOURCES'],
  },
  {
    path: '/sectors',
    name: 'Setores',
    icon: sectorsIcon,
    component: Sectors,
    layout: '/admin',
    sidebar: true,
    access: ['MASTER', 'HUMAN_RESOURCES', 'COMMUNICATION_AGENT'],
  },
];

export default dashboardRoutes;
