import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from '../../common/history';
import userReducer from './useReducer';
import formReducer from './formReducer';
import collaboratorReducer from './collaboratorReducer';
import messagesReducer from './messagesReducer';
import companyReducer from './companyReducer';
import complaintsReducer from './complaintsReducer';
import requestsReducer from './requestsReducer';

const rootReducer = combineReducers({
  router: connectRouter(history),
  userReducer,
  formReducer,
  collaboratorReducer,
  messagesReducer,
  companyReducer,
  complaintsReducer,
  requestsReducer,
});

export default rootReducer;
